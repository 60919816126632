.backdrop {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #c9c2af;
  z-index: 100;
}

.closeBtn {
  position: absolute;
  top: 53px;
  right: 64px;
  background-color: none;
  border: none;
  color: none;
}

.menuList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 159px;
}

.menuItem {
  display: block;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
  margin-bottom: 52px;
}

.closeIcon {
  width: 35px;
  height: 35px;
  color: #f8f8f8;
}

.closeIcon:active {
  color: #555555;
}

@media screen and (max-width: 767.9px) {
  .closeBtn {
    top: 30px;
    right: 26px;
  }

  .menuList {
    margin-top: 141px;
  }

  .menuItem {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 50px;
  }
}
