ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  margin: 0;
  text-decoration: none;
}

* {
  scroll-behavior: smooth;
}

.appWrapper {
  color: #555555;
  background-color: #fff;

  font-family: 'Frank Ruhl Libre', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
}
