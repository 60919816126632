.langIcon {
  color: #fff;
  width: 17px;
  margin-bottom: -5px;
  margin-left: 2px;
}

.dropdownMenu {
  position: relative;
}

.dropdownMenu__button {
  color: #fff;
  background-color: transparent;
  border: none;
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  padding: 0;
  cursor: pointer;
}

.dropdownMenu__options {
  position: absolute;
  width: 100%;
  background-color: #fff;
  color: #c9c2af;
  font-size: 16px;
  line-height: 21px;
  padding: 4px;
  margin-left: -4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 30;
}

.dropdownMenu__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.flagContainer {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  object-fit: contain;
  border-radius: 2px;
}

@media screen and (max-width: 1199.9px) {
  .langIcon {
    color: #c9c2af;
  }

  .dropdownMenu__button {
    color: #c9c2af;
  }
}
