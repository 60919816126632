.logoContainer {
  font-family: 'Inter';
  color: #555555;
  cursor: pointer;
}

.this {
  display: block;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: -30px;
}

.is {
  font-weight: 200;
  font-size: 13px;
  line-height: 16px;
}

.retoucher {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid #555555;
}

@media screen and (min-width: 768px) and (max-width: 1199.9px) {
  .logoContainer {
    margin-right: 0;
  }

  .isRetoucher {
    display: flex;
    align-items: flex-start;
  }

  .is {
    margin-top: 3px;
  }

  .this {
    font-size: 20px;
    line-height: 24px;
  }

  .retoucher {
    font-size: 24px;
    line-height: 29px;
  }
}

@media screen and (max-width: 767.9px) {
  .logoContainer {
    margin: 0 auto;
  }
}
