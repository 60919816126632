.header {
  background-color: rgba(201, 194, 175, 0.72);
  padding-top: 20px;
  padding-bottom: 30px;
  height: 61px;
}

.container {
  margin: 0 auto;
  width: 1280px;
  padding-left: 80px;
  padding-right: 80px;
}

.langContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
  color: #fff;
}

.langSwitcher {
  display: flex;
  align-items: flex-end;
}

.headerContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navItem {
  text-decoration: none;
  color: #fff;
  transition: color 250ms linear;
}

.underline {
  display: block;
  width: 100%;
  height: 2px;
  opacity: 0;
  transition: all 250ms linear;
}

.navItem:hover {
  color: #555;
  border-bottom: 1px solid #555;
}

.navItem:hover .underline {
  opacity: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logoItem {
  transition: all 250ms linear;
}

.logoItem:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.burger {
  display: none;
  justify-content: space-between;
}

.upperLine {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #c9c2af;
  margin-bottom: 5px;
}

.middleLine {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #c9c2af;
  margin-bottom: 5px;
}

.lowerLine {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #c9c2af;
}

@media screen and (max-width: 1439.9px) {
  .header {
    display: inline-block;
  }
}

@media screen and (max-width: 1439.9px) {
  .header {
    display: inline-block;
  }
}

@media screen and (max-width: 1199.9px) {
  .header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 143px;
    padding: 0;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.09);
    border-radius: 0px 0px 45px 45px;
  }

  .container {
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .langContainer {
    font-family: 'Inter';
    font-size: 20px;
    line-height: 24px;

    display: block;
    color: #c9c2af;
    margin-left: 37px;
  }

  .langIcon {
    color: #c9c2af;
  }

  .headerContainer {
    display: block;
    padding: 0;
    margin: 0;
  }

  .navItem {
    display: none;
  }

  .burger {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    margin-right: 17px;
  }

  .select {
    color: #c9c2af;
  }
}

@media screen and (max-width: 767.9px) {
  .header {
    height: 93px;
  }

  .langContainer {
    margin-left: 0;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .burger {
    margin-right: 0;
    padding-right: 0;
  }
}
